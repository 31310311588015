
import { mapGetters } from 'vuex'

import Forgot from '@/components/auth/Forgot'
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  middleware: ['auth', 'guest'],
  auth: 'guest',

  components: {
    Forgot,
    Breadcrumbs
  },

  data () {
    return {
      breadcrumbs: [
        {
          title: this.$t('pages.index.title'),
          to: this.localePath('index'),
          active: false
        },
        {
          title: this.$t('auth.forgot.title'),
          to: '#',
          active: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      logo: 'logo'
    })
  },

  head () {
    return {
      title: this.$t('auth.forgot.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: this.$config.appUrl + this.$route.path
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('auth.forgot.title')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('pages.forgot.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('pages.forgot.title')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        // },
        // {
        //   hid: 'keywords',
        //   name: 'keywords',
        //   content: ''
        }
      ]
    }
  }
}
