
import { mapGetters } from 'vuex'

import Swiper from '@/components/index/Swiper'
import Links from '@/components/index/Links'
import PopUpAdModal from '@/components/modals/PopUpAdModal'
import Recent from '@/components/product/partials/Recent'
import Sale from '@/components/index/Sale'
import PopularProducts from '@/components/index/PopularProducts'
import Journal from '@/components/index/Journal'
import Advantages from '@/components/index/Advantages'
import MobileCatalog from '@/components/index/MobileCatalog'

export default {
  components: {
    Swiper,
    Links,
    PopUpAdModal,
    Recent,
    PopularProducts,
    Sale,
    Journal,
    Advantages,
    MobileCatalog
  },

  async asyncData ({ params, $axios, app }) {
    const index = await app.$cacheFetch(
      {
        key: `get:/client/${app.$config.storeId}/${app.i18n.locale}/${app.store.state.country.iso_code}/pages/index`,
        expire: app.$config.responseCacheLifetime
      },
      async () => {
        return await $axios.$get(`${app.i18n.locale}/${app.store.state.country.iso_code}/pages/index`)
          .catch((error) => {
            console.log(error)
          })
      }
    )

    return {
      page: index.data.page,
      banners: index.data.banners
    }
  },

  data () {
    return {
      isMounted: false,
      popUpAdModalActive: false,
      popUpAdModalTimeout: 5000
    }
  },

  computed: {
    ...mapGetters({
      currency: 'currency',
      country: 'country',
      logo: 'logo',
      settings: 'settings',
      recent: 'recent/recent',
      sale: 'sale/products',
      journal: 'journal/index',
      menus: 'menus',
      store: 'store',
      popupAd: 'modal/popupAd'
    })
  },

  mounted () {
    this.isMounted = true

    setTimeout(() => {
      this.togglePopUpAdModal()
    }, this.popUpAdModalTimeout)
  },

  methods: {
    togglePopUpAdModal () {
      this.popUpAdModalActive = !this.popUpAdModalActive

      if (this.popUpAdModalActive === false) {
        this.$store.dispatch('modal/closePopupAd', true)
      }
    }
  },

  head () {
    return {
      title: this.page.title || this.$t('pages.index.title'),
      link: [
        {
          hid: 'i18n-can',
          rel: 'canonical',
          href: `${this.$config.appUrl}/${this.page.canonical}`
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.page.description || this.$t('pages.index.description')
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.page.schema_org_title || this.$t('pages.index.title')
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.page.schema_org_description || this.$t('pages.index.description')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.logo ? this.logo.image.lazy : this.$config.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: this.page.robots
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.page.keywords
        }
      ]
    }
  }
}
